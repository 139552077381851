<template>
  <div id="app">
    <Nav />
    <router-view />
  </div>
</template>
<script>
import './assets/css/libs.bundle.css';
import './assets/css/theme.bundle.css';
import './assets/css/app.css';
/*
import './assets/js/vendor.bundle.js';
import './assets/js/theme.bundle.js';
 */

import './assets/js/bootstrap.bundle.min.js';

import Nav from '@/components/Nav'
export default {
  components: {Nav},
  name: 'BilnetAnaokullariUzaktanEgitim',
}
</script>