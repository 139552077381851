<template>
  <div>
    <Head title="Arşiv" />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div v-if="! ready" :class="alertClass" class="alert">
            {{ alertMessage }}
          </div>
          <List :data="data" v-else />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Head from '@/components/Head';
import List from '@/components/List';

export default {
  name: 'Archive',
  components: {Head, List},
  head: {
    title: () =>  { return { inner: "Arşiv" }},
  },
  data () {
    return {
      ready: false,
      data: {},
      alertMessage: 'Yükleniyor',
      alertClass: 'alert-info'
    }
  },
  created() {

    let class_num = this.$route.params.class;

    let last_date = false;
    if ((new Date().getMonth()) >= 0 &&  (new Date().getMonth()) < 7) {
      last_date = true;
    }
    let calc_date = (new Date());
    if (last_date) {
      calc_date.setFullYear(calc_date.getFullYear() - 1);
    }

    let uri = 'bilnet-material/' + class_num;
    uri = uri + '/'+(calc_date.getFullYear())+'-08-31/2040-01-01';
    // uri = uri + '/1970-01-01/2040-01-01';



    this.$axios.get(uri).then(response => {
      if (typeof response.data == 'object') {

        if (Object.keys(response.data).length > 0) {

          this.alertMessage = 'Harika, veriler hazır!'
          this.alertClass = 'alert-success'
          this.data = response.data

          setTimeout(() => {
            this.ready = true
            this.alertMessage = ''
            this.alertClass = ''
          }, 1000)

        } else {

          this.alertMessage = 'Henüz hiç kayıt bulunmuyor!'
          this.alertClass = 'alert-light'

        }

      } else {

        this.alertMessage = 'Verileri hazırlarken bir sorun oluştu, tekrar dener misiniz?'
        this.alertClass = 'alert-warning'

      }
    }).catch(error => {

      this.alertMessage = 'Sunucudan şu an bir yanıt alamıyoruz!'
      this.alertClass = 'alert-danger'

      throw error;
    });
  }
}
</script>