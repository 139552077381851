<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-5 col-xl-4 my-5">
        <div class="text-center">
          <h6 class="text-uppercase text-muted mb-4">
            404 HATASI
          </h6>
          <h1 class="display-4 mb-3">
            Sayfa Bulunamadı! 😭
          </h1>
          <p class="text-muted mb-4">
            Üst menüden ana sayfa ya da arşive giderek tekrar dener misiniz?
          </p>
          <router-link :to="{ name: 'Home'}" class="btn btn-lg btn-primary">
            Ana Sayfa
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotFound',
  head: {
    title: () => {
      return {inner: "Sayfa Bulunamadı!"}
    },
  },
}
</script>