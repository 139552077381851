import axios from 'axios'

/*
if (localStorage.getItem('token') != null) {
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
} else {
    let params = new URLSearchParams(window.location.search.substring(1));
    if (params.get("token") != null) {
        localStorage.setItem('token', params.get("token"))
        axios.defaults.headers.common['Authorization'] = params.get("token")
    }
}
*/
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL // "https://bilpanel.bilfen.com/"
});
export default axiosInstance;