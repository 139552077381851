<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-dark " id="topnav">
    <div class="container">
      <button class="navbar-toggler me-auto" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbar"
              aria-controls="navbar"
              aria-expanded="false"
              aria-label="Navigasyonu Tetikle">
        <span class="navbar-toggler-icon"></span>
      </button>
      <router-link class="navbar-brand" :to="{ name: 'Home' }">
        <img :src="require('@/assets/img/bilnet-logo.png')" alt="..." class="navbar-brand-img">
      </router-link>
      <div class="collapse navbar-collapse" id="navbar">
        <ul class="navbar-nav me-lg-auto">
          <!--
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle active" href="#" id="topnavDashboards" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
              Ana Sayfa
            </a>
          </li>
          -->
          <router-link v-if="this.$route.name != 'Home'" class="btn btn-sm btn-light mx-2" :to="{ name: 'Home' }">Bu Hafta</router-link>
          <router-link v-else class="btn btn-sm btn-light mx-2" :to="{ name: 'Archive' }">Arşiv</router-link>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'Nav',
}
</script>