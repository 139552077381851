import Vue from 'vue'
import VueRouter from 'vue-router';
import App from './App.vue'

Vue.config.productionTip = false

import NProgress from 'nprogress'
import axios from './helpers/http'
import plyr from 'plyr'

Vue.prototype.$axios = axios;
Vue.prototype.$nprogress = NProgress;
Vue.prototype.$plyr = plyr;

Vue.use(VueRouter);

import Home from './views/Home.vue';
import NotFound from './views/NotFound.vue';
import Archive from './views/Archive.vue';

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { name: 'ArchiveNotFound', path: '/arsiv', component: NotFound },
    { name: 'Archive', path: '/arsiv/:class', component: Archive },
    { name: 'HomeNotFound', path: '/', component: NotFound },
    { name: 'Home', path: '/:class', component: Home },
    { name: 'HomeFull', path: '/:class/:start_date', component: Home },
    { name: '404', path: '/404', component: NotFound },
    { path: '*', redirect: '/404' }
  ]
});

import VueHead from 'vue-head'
Vue.use(VueHead, {
  seperator: "-",
  complement: "Bilnet Anaokulları"
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
