<template>
  <div class="header bg-dark pt-9 pb-9"
       :style="{
        'background': 'url('+require('@/assets/img/cover.png')+') no-repeat center center'}">
    <div class="container">
      <div class="header-body border-0">
        <div class="row align-items-center">
          <div class="col">
            <h1 class="font-weight-bold text-white mb-2 display-4">
              {{ titleDecode + title }}
            </h1>
            <p class="font-size-lg text-muted mb-0">
              Bilnet Anaokulları
            </p>
          </div>
          <!--
          <div class="col-auto">
              <a href="#" class="btn btn-lg btn-light">
                Örnek
              </a>
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
  <!--
  <div class="header-body border-0">
    <div class="row align-items-end">
      <div class="col">
        <h6 class="header-pretitle">
          Bilnet Anaokulları - Uzaktan Eğitim
        </h6>
        <h1 class="header-title">
          {{ title }}
        </h1>
      </div>
    </div>
  </div>
  -->
</template>
<script>
export default {
  name: 'Head',
  props: ['title'],
  computed: {
    titleDecode: function () {
      if (this.$route.params.class !== undefined) {

        switch (this.$route.params.class) {
          case 'eccbc87e4b5ce2fe28308fd9f2a7baf3':
            return '3 Yaş - ';
            break;

          case 'a87ff679a2f3e71d9181a67b7542122c':
            return '4 Yaş - ';
            break;

          case 'e4da3b7fbbce2345d7772b0674a318d5':
            return '5 Yaş - ';
            break;

          default:
              return '';
              break;
        }

      } else {
        return '';
      }
    }
  }
}
</script>