<template>
 <div>
   <div class="card card-fill" :key="i" v-for="(contents, i) in data">
     <div class="card-header bg-dark text-white">
       <h4 class="card-header-title">
         {{ formatDate(i) }}
       </h4>
     </div>
     <div class="card-body">
       <div class="row">
         <div class="col-12 col-sm-4 col-lg-3" :key="index" v-for="(content, index) in contents">
           <a class="card lift bilnet-content"
              v-on:click="videoSrc = content.src; videoTitle = content.title"
              :data-bs-toggle="content.type != 'download' ? 'modal' : ''"
              :data-bs-target="content.type != 'download' ? '#frame' : ''"
              :target="content.type != 'download' ? '' : '_blank'"
              :href="content.type != 'download' ? 'javascript:;' : content.src">
             <div class="card-body text-center">
               <div class="card-avatar avatar avatar-lg mx-auto">
                 <svg viewBox="0 0 24 24" v-if="content.type == 'video'">
                   <path fill="currentColor" d="M10 16.5L16 12L10 7.5M22 12C22 6.46 17.54 2 12 2C10.83 2 9.7 2.19 8.62 2.56L9.32 4.5C10.17 4.16 11.06 3.97 12 3.97C16.41 3.97 20.03 7.59 20.03 12C20.03 16.41 16.41 20.03 12 20.03C7.59 20.03 3.97 16.41 3.97 12C3.97 11.06 4.16 10.12 4.5 9.28L2.56 8.62C2.19 9.7 2 10.83 2 12C2 17.54 6.46 22 12 22C17.54 22 22 17.54 22 12M5.47 3.97C6.32 3.97 7 4.68 7 5.47C7 6.32 6.32 7 5.47 7C4.68 7 3.97 6.32 3.97 5.47C3.97 4.68 4.68 3.97 5.47 3.97Z" />
                 </svg>
                 <svg viewBox="0 0 24 24" v-else-if="content.type == 'download'">
                   <path fill="currentColor" d="M17,13L12,18L7,13H10V9H14V13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
                 </svg>
                 <svg viewBox="0 0 24 24" v-else>
                   <path fill="currentColor" d="M16.5,9L13.5,12L16.5,15H22V9M9,16.5V22H15V16.5L12,13.5M7.5,9H2V15H7.5L10.5,12M15,7.5V2H9V7.5L12,10.5L15,7.5Z" />
                 </svg>
               </div>
               <h2 class="mb-3">
                 {{ content.title }}
               </h2>
             </div>
           </a>
         </div>
       </div>
     </div>
   </div>
   <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="frame" tabindex="-1" aria-labelledby="frameLabel" aria-hidden="true">
     <div class="modal-dialog modal-xl modal-dialog-centered">
       <div class="modal-content border-0">
         <div class="modal-header bg-dark text-white">
           <h5 class="modal-title" id="exampleModalLabel">{{ videoTitle }}</h5>
           <button type="button" class="text-white btn btn-dark" data-bs-dismiss="modal" v-on:click="resetFrame()" aria-label="Kapat">
             <svg style="width:24px;height:24px" class="mr-2" viewBox="0 0 24 24">
               <path fill="currentColor" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z" />
             </svg>
           </button>
         </div>
         <div class="modal-body p-0">
           <div class="ratio ratio-16x9">
             <iframe :src="videoSrc" allowfullscreen></iframe>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
</template>
<script>
export default {
  name: 'List',
  props: ['data'],
  data () {
    return {
      videoSrc: '',
      videoTitle: ''
    }
  },
  methods: {
    formatDate(date) {
      return new Date(date).
        toLocaleString('tr-TR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    },
    resetFrame() {
      setTimeout(() => {
        this.videoSrc = ''
        this.videoTitle = ''
      }, 100)
    }
  },
  created() {
  }
}
</script>