<template>
  <div>
    <Head title="Eğitim İçerikleri" />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div v-if="! ready" :class="alertClass" class="alert">
            {{ alertMessage }}
          </div>
          <List :data="data" v-else />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Head from '@/components/Head';
import List from '@/components/List';

export default {
  name: 'Home',
  components: {Head, List},
  head: {
    title: () =>  { return { inner: "Eğitim İçerikleri" }},
  },
  data () {
    return {
      ready: false,
      data: {},
      alertMessage: 'Yükleniyor',
      alertClass: 'alert-info'
    }
  },
  created() {

    let class_num = this.$route.params.class;
    let start_date = this.$route.params.start_date;

    let uri = 'bilnet-material/' + class_num;

    if (start_date !== undefined) {
      uri = uri + '/' + start_date;
    }

    this.$axios.get(uri).then(response => {
      if (typeof response.data == 'object') {

        if (Object.keys(response.data).length > 0) {

          this.alertMessage = 'Harika, veriler hazır!'
          this.alertClass = 'alert-success'
          this.data = response.data

          setTimeout(() => {
            this.ready = true
            this.alertMessage = ''
            this.alertClass = ''
          }, 1000)

        } else {

          this.alertMessage = 'Henüz hiç kayıt bulunmuyor!'
          this.alertClass = 'alert-light'

        }

      } else {

        this.alertMessage = 'Verileri hazırlarken bir sorun oluştu, tekrar dener misiniz?'
        this.alertClass = 'alert-warning'

      }
    }).catch(error => {

      this.alertMessage = 'Sunucudan şu an bir yanıt alamıyoruz!'
      this.alertClass = 'alert-danger'

      throw error;
    });
  }
}
</script>